import {
  MuteStatus,
  NotificationPreferences,
  Channel,
} from '@wix/ambassador-notifications-v1-notification-preferences/types';
import { ChannelPreferences, Preference } from '../../types';

const DISPLAYED_CHANNELS = [Channel.WEB_FEED, Channel.EMAIL, Channel.SMS];

export function parseChannelsFromPreferences(
  preferences: NotificationPreferences,
) {
  const channels: ChannelPreferences[] = [];
  preferences?.preferencesInfo?.forEach(
    ({ topicId, channel, stateData, appInfo, topicType }) => {
      if (appInfo?.displayName && DISPLAYED_CHANNELS.includes(channel!)) {
        const newPreference: Preference = {
          id: topicId!,
          displayName: stateData?.displayName!,
          isMuted: stateData?.state?.muteStatus === MuteStatus.MUTED,
          topicType,
        };
        const channelIndex = channels.findIndex((c) => c.channel === channel!);
        if (channelIndex >= 0) {
          const appIndex = channels[channelIndex].preferencesByApp.findIndex(
            (s) => s.appId === appInfo?.id,
          );
          if (appIndex >= 0) {
            channels[channelIndex].preferencesByApp[appIndex].preferences.push(
              newPreference,
            );
          } else {
            channels[channelIndex].preferencesByApp.push({
              appId: appInfo.id!,
              appDisplayName: appInfo?.displayName!,
              preferences: [newPreference],
            });
          }
        } else {
          channels.push({
            channel,
            preferencesByApp: [
              {
                appId: appInfo.id!,
                appDisplayName: appInfo?.displayName!,
                preferences: [newPreference],
              },
            ],
          });
        }
      }
    },
  );

  return channels.sort(
    (channelA, channelB) =>
      DISPLAYED_CHANNELS.indexOf(channelA.channel!) -
      DISPLAYED_CHANNELS.indexOf(channelB.channel!),
  );
}

export const getPreferenceInfo = (
  topicId: string | undefined,
  channel: Channel,
  mute: boolean,
) => ({
  topicId,
  channel,
  stateData: {
    state: {
      muteStatus: mute ? MuteStatus.MUTED : MuteStatus.UNMUTED,
    },
  },
});
