import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { MarketingConsentDetailsIdentifierType } from '@wix/ambassador-marketing-marketing-consent-v1-marketing-consent/types';
import { Channel } from '@wix/ambassador-notifications-v1-notification-preferences/types';
import { membersAreaNotificationSettingsPageStartLoading } from '@wix/bi-logger-identity-data/v2';
import {
  getMarketingConsent,
  setMarketingConsent,
} from '../../apis/marketing-consent/marketing-consent';
import {
  getChannelPreferences,
  setPreference,
} from '../../apis/preferences-server/preferences-server';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const startLoadingTime = Number(new Date());
  if (!flowAPI.environment.isSSR) {
    flowAPI.bi?.report(membersAreaNotificationSettingsPageStartLoading({}));
  }
  const channelPreferences = await getChannelPreferences({
    flowAPI,
    channel: Channel.EMAIL,
  });
  const { isSubscribed } = await getMarketingConsent({
    flowAPI,
    type: MarketingConsentDetailsIdentifierType.EMAIL,
  });
  const onChangeSubscription = async (shouldSubscribe: boolean) =>
    setMarketingConsent({
      flowAPI,
      type: MarketingConsentDetailsIdentifierType.EMAIL,
      shouldSubscribe,
    });
  const onChangePreference = async (id: string, mute: boolean) =>
    setPreference({
      flowAPI,
      channel: Channel.EMAIL,
      mute,
      id,
    });

  return {
    async pageReady() {
      controllerConfig.setProps({
        startLoadingTime,
        isSubscribed,
        channelPreferences,
        onChangeSubscription,
        onChangePreference,
        fitToContentHeight: true,
      });
    },
  };
};

export default createController;
