import {
  getPreferences,
  upsertPreferences,
} from '@wix/ambassador-notifications-v1-notification-preferences/http';
import {
  Channel,
  MuteStatus,
  Type,
} from '@wix/ambassador-notifications-v1-notification-preferences/types';
import type { OOIControllerFlowAPI } from '@wix/yoshi-flow-editor-runtime/build/cjs/flow-api/OOIController';
import type { ChannelPreferences } from '../../types';
import { parseChannelsFromPreferences } from './get-preferences-parser';

export const getChannelPreferences = async ({
  flowAPI,
  channel,
}: {
  flowAPI: OOIControllerFlowAPI;
  channel: Channel;
}): Promise<ChannelPreferences | undefined> => {
  if (shouldGetDummyData(flowAPI)) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return getDummyPreferences();
  }
  return flowAPI.httpClient
    .request(getPreferences({}))
    .then(({ data: { notificationPreferences } }) =>
      parseChannelsFromPreferences(notificationPreferences!).find(
        (c) => c.channel === channel,
      ),
    );
};

export const setPreference = async ({
  flowAPI,
  channel,
  mute,
  id,
}: {
  flowAPI: OOIControllerFlowAPI;
  channel: Channel;
  mute: boolean;
  id?: string;
}): Promise<void> => {
  flowAPI.httpClient.request(
    upsertPreferences({
      preferencesInfo: [
        {
          topicId: id,
          channel,
          stateData: {
            state: {
              muteStatus: mute ? MuteStatus.MUTED : MuteStatus.UNMUTED,
            },
          },
        },
      ],
    }),
  );
};

const shouldGetDummyData = (flowAPI: OOIControllerFlowAPI) =>
  flowAPI.environment.isEditor || flowAPI.environment.isPreview;

const getDummyPreferences = (): ChannelPreferences => ({
  channel: Channel.EMAIL,
  preferencesByApp: [
    {
      appId: '14724f35-6794-cd1a-0244-25fd138f9242',
      appDisplayName: 'Forum',
      preferences: [
        {
          id: '35e25776-de0c-4909-a975-aaa5b637c210',
          displayName: 'Daily or weekly activity summary',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
        {
          id: '859c9189-702e-455d-b000-1c2e27fa21f2',
          displayName: 'Comments or replies',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
        {
          id: '2e461c53-1778-4bd0-9d68-8f167c474fd4',
          displayName: 'Likes or reactions',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
        {
          id: 'b24a6474-35ad-49d5-acbb-3e1ce275b083',
          displayName: 'New posts',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
      ],
    },
    {
      appId: '141fbfae-511e-6817-c9f0-48993a7547d1',
      appDisplayName: 'Inbox',
      preferences: [
        {
          id: 'dffe26d8-24c2-423c-a342-143c1754d83b',
          displayName: 'New messages',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
      ],
    },
    {
      appId: '14bcded7-0066-7c35-14d7-466cb3f09103',
      appDisplayName: 'Blog',
      preferences: [
        {
          id: '8b1af8f9-e4ca-448a-9b68-45308f6c6be8',
          displayName: 'Posts or comments are liked',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
        {
          id: '0e6b21f1-68fc-44d3-8dea-60ab6935a52e',
          displayName: 'Comments on posts',
          isMuted: false,
          topicType: Type.PROMOTIONAL,
        },
      ],
    },
    {
      appId: '13ee94c1-b635-8505-3391-97919052c16f',
      appDisplayName: 'Invoices',
      preferences: [
        {
          id: 'f267a040-35af-4d5a-ac53-59d8d649cfe0',
          displayName: 'Invoice issued',
          isMuted: false,
          topicType: Type.TRANSACTIONAL,
        },
        {
          id: '445115c0-6d55-4016-b462-b186c3eb8bef',
          displayName: 'Invoice is overdue',
          isMuted: false,
          topicType: Type.TRANSACTIONAL,
        },
      ],
    },
  ],
});
