
  import userController from '/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/components/PreferencesOoi/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://ed436f5053144538958ad06a5005e99a@sentry.wixpress.com/6142',
    id: 'undefined',
    projectName: 'members-area-notifications--0j',
    teamName: 'ping',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.title":"Email notification Settings","app.widget.subtitle":"Choose the notifications you want to get","app.widget.PROMOTIONAL.title":"Marketing emails","app.widget.TRANSACTIONAL.title":"Business transactional emails ","app.widget.marketing-emails.subscription.title.subscribed":"You are subscribed to marketing emails","app.widget.marketing-emails.subscription.title.unsubscribed":"You are not subscribed to marketing emails","app.widget.marketing-emails.subscription.subscribe":"Subscribe Now","app.widget.marketing-emails.subscription.unsubscribe":"Unsubscribe","app.widget.marketing-emails.subscription-toast.success.unsubscribed":"You have unsubscribed from all marketing emails. You will still receive important emails about your account.","app.widget.marketing-emails.subscription-toast.success.subscribed":"You have subscribed to all marketing emails.","app.widget.marketing-emails.subscription-toast.error":"Email preferences not saved due to a technical error on our end. Try again","app.settings.label":"Settings","app.settings.tabs.design":"Design","app.settings.tabs.design.text-color.title":"Text color","app.settings.tabs.design.title-font.title":"Title text","app.settings.tabs.design.primary-font.title":"Body text","app.settings.tabs.design.button-section.title":"Button style","app.settings.tabs.design.button-text-color.title":"Button text color","app.settings.tabs.design.button-opacity-and-color.title":"Button opacity and color","app.settings.tabs.design.background-section.title":"Backgrounds and borders","app.settings.tabs.design.background-color.title":"Background opacity and color","app.settings.tabs.design.border-color.title":"Border opacity and color","app.settings.tabs.design.border-width.title":"Border width"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Members Notifications Settings",
    componentName: "PreferencesOoi",
    appDefinitionId: "14f25dc5-6af3-5420-9568-f9c5ed98c9b1",
    componentId: "14f25dd2-f9b0-edc2-f38e-eded5da094aa",
    projectName: "members-area-notifications-preferences",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/63f463de1bc2bc5f/packages/members-area-notifications-preferences/src/components/PreferencesOoi/controller.ts",
  };

  export const controller = _controller
  export default controller;
