import { MarketingConsentDetailsIdentifierType } from '@wix/ambassador-marketing-marketing-consent-v1-marketing-consent/types';
import type { OOIControllerFlowAPI } from '@wix/yoshi-flow-editor-runtime/build/cjs/flow-api/OOIController';

interface Props {
  flowAPI: OOIControllerFlowAPI;
  type: MarketingConsentDetailsIdentifierType;
  shouldSubscribe?: boolean;
}

const BASE_URL = '/_api/ping-serverless/';

export const getMarketingConsent = async ({ flowAPI, type }: Props) => {
  if (shouldGetDummyData(flowAPI)) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return { isSubscribed: true };
  }
  const { data } = await flowAPI.httpClient.get<{ isSubscribed: boolean }>(
    `${BASE_URL}member-get-marketing-consent?identifierType=${type}`,
  );
  return data;
};

export const setMarketingConsent = async ({
  flowAPI,
  type,
  shouldSubscribe,
}: Props) =>
  flowAPI.httpClient.post(`${BASE_URL}member-set-marketing-consent`, {
    shouldSubscribe,
    identifierType: type,
  });

const shouldGetDummyData = (flowAPI: OOIControllerFlowAPI) =>
  flowAPI.environment.isEditor || flowAPI.environment.isPreview;
